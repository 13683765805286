import React, { useEffect, useLayoutEffect, useRef } from "react";

export const VideoComp = ({ src }) => {
  const videoRef = useRef(null);
  useLayoutEffect(() => {
    const videoElement = videoRef.current;
    const handlePlayPause = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      });
    };
    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.5,
    });
    const handleEnded = () => {
      videoElement.currentTime = 0;
      videoElement.play();
      //   videoElement.muted = false;
    };
    if (videoElement) {
      observer.observe(videoElement);
      videoElement.addEventListener("ended", handleEnded);
    }
    // console.log(videoElement);
    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
        videoElement.removeEventListener("ended", handleEnded);
      }
    };
  }, [src]);

  return (
    <video ref={videoRef} muted autoPlay playsInline>
      <source src={src} type="video/mp4" />
    </video>
  );
};

