import React from "react";

export const Footer = () => {
  return (
    <div className="footer_container">
      <div className="footer_que">© 2024 Nikles. All Rights Reserved</div>
      <span className="show_desktop">
        <div className="footer_list">
          {[
            "Privacy Policy",
            "Cookie Policy",
            "About",
            "Products",

            "Return Policy",
            "Terms& Condition",

            "Contact",
          ].map((el, i) => (
            <div>{el}</div>
          ))}
        </div>
      </span>
      <span className="show_mobile">
        <div className="footer_list">
          {[
            "Privacy Policy",
            "Cookie Policy",
            "About",
            "Products",

            "Media",
            "Imprint",

            "Contact",
          ].map((el, i) => (
            <div>{el}</div>
          ))}
        </div>
      </span>
    </div>
  );
};
