import React from "react";
import { Svgs } from "../../component/Svgs";

export const Section8 = () => {
  return (
    <div className="section8_container">
      <div className="section8_header show_desktop">Follow Us</div>
      <div className="section8_header show_mobile">Join Us</div>
      <div className="section8_name">Nikles Eco in Social Media</div>
      <div className="section8_social_list">
        <Svgs img_var={"linkdin"}/>
        <Svgs img_var={"youtube"}/>
        <Svgs img_var={"instagram"}/>
        <Svgs img_var={"facebook"}/>
      </div>
    </div>
  );
};
