import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Home } from "./pages/home/Home";
import { useState } from "react";
import store from "./store/store";
import { NavBar } from "./component/NavBar";

function App() {
  const [loading, setloading] = useState(false);
  const routes = [
    {
      path: "/",
      element: <Home setloading={setloading} />,
    },
  ];
  const route = useRoutes(routes);
  return (
    <>
      <Provider store={store}>
        <NavBar />
        {route}
      </Provider>
    </>
  );
}

export default App;
