import React, { useState } from "react";
import { Svgs } from "./Svgs";

export const NavBar = () => {
  const [DropActive, setDropActive] = useState(null);
  const [MenuActive, setMenuActive] = useState(false);
  const NavList = [
    {
      name: "About",
      drop: true,
      list: [
        {
          name: "About Nikles Eco",
          linkto: "",
        },
        {
          name: "Media",
          linkto: "",
        },
        {
          name: "Imprint",
          linkto: "",
        },
      ],
    },
    {
      name: "Products",
      drop: true,
      list: [
        {
          name: "Showerhead",
          linkto: "",
        },
        {
          name: "Hand Shower",
          linkto: "",
        },
        {
          name: "Basin Faucet",
          linkto: "",
        },
      ],
    },
    {
      name: "Technologies",
      drop: false,
      linkto: "technologies",
    },
    {
      name: "Contact",
      drop: false,
      linkto: "contact",
    },
  ];
  window.addEventListener("click", () => {
    if (DropActive != null) {
      setDropActive(null);
    }
  });
  return (
    <>
      <div className="show_desktop"
      onClick={(e)=>{
        e.stopPropagation()
      }}
      >
        <div className="nav_container_desk">
          <div className="nav_logo_section">
            <Svgs img_var={"logo"} />
          </div>
          <div className="nav_list_section">
            {NavList.map((el, i) => (
              <div key={i} className="list_box">
                <div
                  className="list_box_name"
                  style={
                    DropActive == i + 1
                      ? {
                          backgroundColor: "var(--base-light)",
                        }
                      : {}
                  }
                  onClick={() => {
                    setDropActive((prev) => (prev == i + 1 ? null : i + 1));
                  }}
                >
                  {el.name}
                  {el.drop ? (
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={
                        DropActive != i + 1
                          ? {
                              transform: "rotate(180deg)",
                              transition: "transform 0.3s linear",
                            }
                          : {
                              transition: "transform 0.3s linear",
                            }
                      }
                    >
                      <path
                        d="M18 15.5L12 9.5L6 15.5"
                        stroke="#102000"
                        stroke-linecap="round"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
                {el.drop && DropActive == i + 1 ? (
                  <div className="list_box_drop">
                    {el?.list?.map((el1, i) => (
                      <div className="list_box_drop_name">{el1?.name}</div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
          <div className="nav_extra_section">
            {/* <div>
              <Svgs img_var={"profile"} />
            </div> */}
            <div>
              <Svgs img_var={"cart"} />
            </div>
          </div>
        </div>
      </div>
      <div className="show_mobile"
       onClick={(e)=>{
        e.stopPropagation()
      }}
      >
        <div className="nav_container_mobile">
          <div
            className="nav_logo_menu"
            onClick={() => {
              setMenuActive(true);
            }}
          >
            <Svgs img_var={"menu"} />
          </div>
          <div className="nav_logo_section">
            <Svgs img_var={"logo"} />
          </div>

          <div className="nav_extra_section">
            {/* <div>
              <Svgs img_var={"profile"} />
            </div> */}
            <div>
              <Svgs img_var={"cart"} />
            </div>
          </div>
        </div>
        <div className={"menu_container" + (MenuActive ? " active_menu" : "")}>
          <div className="menu_section">
            <div
              className="menu_close"
              onClick={() => {
                setMenuActive(false);
              }}
            >
              <div>
                <Svgs img_var={"close"} />
              </div>
            </div>
            <div className="menu_list">
              {NavList?.map((el, i) => (
                <div key={i} className="menu_box">
                  <div
                    className="menu_box_name"
                    onClick={() => {
                      setDropActive((prev) => (prev == i + 1 ? null : i + 1));
                    }}
                  >
                    {el.name}
                    {el.drop ? (
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={
                          DropActive != i + 1
                            ? {
                                transform: "rotate(180deg)",
                                transition: "transform 0.3s linear",
                              }
                            : {
                                transition: "transform 0.3s linear",
                              }
                        }
                      >
                        <path
                          d="M18 15.5L12 9.5L6 15.5"
                          stroke="#102000"
                          stroke-linecap="round"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="menu_box_list"
                    style={
                      el.drop && DropActive == i + 1
                        ? {
                            height: "110px",
                          }
                        : {
                            height: 0,
                          }
                    }
                  >
                    {el?.list?.map((el1, i1) => (
                      <div className="menu_box_list_name" key={i1}>
                        {el1?.name}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {/* <div className="menu_profile">Profile</div> */}
              <div className="menu_cart">Cart</div>
            </div>
            <div className="menu_social_icons">
              {["linkdin", "twitter", "facebook", "instagram", "youtube"]?.map(
                (el, i) => (
                  <div>
                    <Svgs img_var={el} />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
